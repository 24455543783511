/*DO NOT EDIT THIS FILE. IT WAS AUTOMATICALLY GENERATED.*/
@tailwind utilities;
@tailwind screens;
@tailwind components;
@tailwind base;
.h-full-minus-navbar {
    height: calc(100% - 60px);
    height: -webkit-calc(100% - 60px); /* google, safari */
    height: -moz-calc(100% - 60px); /* firefox */
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@layer base {
    body {
        font-family: "Nunito Sans", sans-serif;
        --tw-bg-opacity: 1;
        background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    }
    .woot--bubble-holder {
        overflow: hidden;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .loader {
        overflow: hidden;
        z-index: 60;
        height: 50px;
    }

    .loader-inner {
        height: 30px;
        margin: auto;
        position: relative;
        width: 30px;
    }
    .loader-line-wrap {
        animation: spin 3000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
        box-sizing: border-box;
        height: 25px;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        transform-origin: 50% 100%;
        width: 50px;
    }
    .loader-line {
        border: 4px solid transparent;
        border-radius: 100%;
        box-sizing: border-box;
        height: 50px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
    }
    .loader-line-wrap:nth-child(1) {
        animation-delay: -50ms;
    }
    .loader-line-wrap:nth-child(2) {
        animation-delay: -100ms;
    }
    .loader-line-wrap:nth-child(3) {
        animation-delay: -150ms;
    }
    .loader-line-wrap:nth-child(4) {
        animation-delay: -200ms;
    }
    .loader-line-wrap:nth-child(5) {
        animation-delay: -250ms;
    }

    .loader-line-wrap:nth-child(1) .loader-line {
        border-color: rgba(242, 149, 0, 1);
        height: 45px;
        width: 45px;
        top: 3px;
    }

    .loader-line-wrap:nth-child(3) .loader-line {
        border-color: rgba(242, 149, 0, 0.8);
        height: 31px;
        width: 31px;
        top: 10px;
    }

    .loader-line-wrap:nth-child(5) .loader-line {
        border-color: rgba(242, 149, 0, 0.6);
        height: 17px;
        width: 17px;
        top: 17px;
    }
    @keyframes spin {
        0%,
        15% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

}
@layer components {
    .btn-selected {
        @apply font-semibold bg-gray-800 text-white px-3 py-2 rounded-md text-sm hover:cursor-pointer;
    }

    .btn {
        @apply font-medium text-gray-50 px-3 py-2 rounded-md text-sm hover:cursor-pointer hover:font-semibold hover:bg-gray-600 hover:text-white;
    }

    .icon {
        @apply w-4 mr-2 transform hover:text-secondary hover:scale-110;
    }

    .badge {
        @apply py-1 px-3 rounded-full text-xs whitespace-nowrap;
    }
    .transition-expand-height {
        transition: max-height 1s ease-in-out;
    }

    .transition-expand {
        -webkit-transition: max-height 1s;
        -moz-transition: max-height 1s;
        -ms-transition: max-height 1s;
        -o-transition: max-height 1s;
        transition: max-height 1s;
        overflow: hidden;
    }
    .transition-all-fast {
        transition: all ease-in-out 0.1s;
    }
    .transition-all-smooth {
        transition: all ease-in-out 0.5s;
    }

    .transition-expand-500 {
        -webkit-transition: max-height .5s;
        -moz-transition: max-height .5s;
        -ms-transition: max-height .5s;
        -o-transition: max-height .5s;
        transition: max-height .5s;
        overflow: hidden;
    }
    .transition-width-smooth {
        transition: width 0.5s, transform 0.5s;
    }
    .bg-primary {
        background-color: rgba(171, 202, 233, 1);
    }

    .bg-secondary {
        background-color: rgba(242, 169, 0, 1);
    }

    .text-secondary {
        color: rgba(242, 169, 0, 1);
    }
}
    .Heading1 {
        font-family: Poppins, sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 56px;
        letter-spacing: 0;
        text-align: left;
    }

    .Heading2 {
        font-family: Poppins, sans-serif;
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;
        letter-spacing: 0;
        text-align: left;
    }

    .Heading3 {
        font-family: Poppins, sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodyMedium {
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodyLight {
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodySmallMedium {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
    }

    .BodySmallLight {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0;
        text-align: left;
    }

    .ShadowS {
        box-shadow: 0px 56px 70px 0px rgba(255, 255, 255, 0.14);
    }

    .ShadowM {
        box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px rgba(30, 30, 30, 0.03);
        box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px rgba(30, 30, 30, 0.04);
        box-shadow: 0px 100px 80px 0px rgba(30, 30, 30, 0.07);
    }

    .ShadowL {
        box-shadow: 0px 1.9932421445846558px 1.5945937633514404px 0px rgba(0, 0, 0, 0.02);
        box-shadow: 0px 4.528800010681152px 3.623039960861206px 0px rgba(0, 0, 0, 0.02);
        box-shadow: 0px 7.882175445556641px 6.3057403564453125px 0px rgba(0, 0, 0, 0.03);
        box-shadow: 0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 19.31982421875px 15.455859184265137px 0px rgba(0, 0, 0, 0.04);
        box-shadow: 0px 30.14707374572754px 24.117658615112305px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 50.048828125px 40.0390625px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
    }

    .pageContainer {
        min-height: 100vh;
        width: 100vw;
        max-width: 100vw;
        background: #f3f4f6;
    }

    .mainContainer {
        display: flex;
        height: calc(100vh - 60px);
        height : -moz-calc(100vh - 60px);
        width: 100%;
    }
    .h-full-minus-navbar {
        height: calc(100vh - 60px);
        height : -moz-calc(100vh - 60px);
        min-height: calc(100vh - 60px);
        min-height : -moz-calc(100vh - 60px);
        max-height: calc(100vh - 60px);
        max-height : -moz-calc(100vh - 60px);
    }
    .bodyContainer {
        display : flex;
        height: 100%;
        width:100%;
        flex-direction: column;
        gap:32px;
        padding : 32px;
    }

